.footer {
  min-height: 400px;
  margin: 150px 0 0;
}

.footer .image-map {
  width: 100%;
  margin: 30px 0 50px;
  border-radius: 10px;
  border: 1px solid black;
  max-width: 450px;
}

.footer p {
  margin: 10px 0 0;
}

.footer p span{
  font-weight: 500;
  display: block;
}

.footer h3 {
  margin-bottom: 20px;
}

.footer .social-logo {
  height: 50px;
  width: 50px;
}

.footer .social-logo:hover {
  opacity: .8;
}

@media screen and (min-width: 768px) {

  .footer p {
    margin: 0 0 10px;
  }

  .footer .image-map {
    margin: 0 0 50px;
  } 
}