.title-with-text-block .wrapper {
  margin-top: 20px;
}

.title-with-text-block h4 {
  margin: 0;
  font-size: 20px;
}

.title-with-text-block p {
  margin: 0;
}