.desktop-nav {
  border-bottom: 2px solid #383838;
  padding: 14px 0;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #f9f9f9;
}

.desktop-nav .nav-icon {
  height: 55px;
}

.desktop-nav .nav-items-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}

.desktop-nav .nav-items-wrapper a {
  color: #000;
  text-decoration: none;
  margin: 0 20px;
  font-weight: 500;
  transition: border 0.4s;
  border-bottom: 1px solid #f9f9f9;
}

.desktop-nav .nav-items-wrapper a.active {
  font-weight: 700;
}

.desktop-nav .nav-items-wrapper a:hover {
  border-bottom: 1px solid #000;
}

.desktop-nav .nav-items-wrapper a:first-child {
  margin: 0 20px 0 0;
}

.desktop-nav-height {
  height: 85px; 
  display: block;
}