body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-padding {
  padding: 0;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

p {
  font-size: 16px;
  margin: 0px;
}

a {
  color: #000;
}

.space-vertical-lg {
  padding-top: 50px;
  padding-bottom: 50px;
}

.space-vertical-md {
  padding-top: 30px;
  padding-bottom: 30px;
}

.space-top-lg {
  padding-top: 50px;
}

.space-top-md {
  padding-top: 30px;
}

.space-top-sm {
  padding-top: 10px;
}

.space-bottom-md {
  padding-bottom: 30px;
}

.space-bottom-sm {
  padding-bottom: 10px;
}


.border-bottom { 
  border-bottom : 1px solid;
}

.border-top {
  border-top: 1px solid;
}

.bg {
  background-color: #f9f9f9;
  border-color: #eee;
}

.center-y {
  display: flex;
  height: 100%;
  align-items: center;
}

.center-x-y {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.spacer {
  height: 30px;
}

@media screen and (min-width: 768px) {
  p {
    font-size: 18px;
  }
}