.hero-block-wrapper {
  position: relative;
}

.hero-block-bg {
  padding: 0;
  overflow: hidden;
  max-height: 900px;
}

.hero-block-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}

.hero-block .hero-logo {
  width: 100%;
  max-width: 250px;
  display: inline-block;
}

.logo-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .hero-block .hero-logo {
    max-width: 450px;
  }
}