.video-bg-wrapper video {
  width: 100%;
  min-height: 100%;
  display: block;
}

@media screen and (min-width: 2000px) {
  .video-bg-wrapper {
    transform: translateY(-14%);  
  }
}