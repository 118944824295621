.previous-job .image {
  height: 408px;
  width: 100%;
  background-size: cover;
  border-radius: 5px;
}

.previous-job p {
  margin-top: 10px;
}

.previous-job h3 {
  margin: 10px 0;
  font-size: 20px;
  border: none;
  padding: 30px 0 0;
  font-weight: 500;
}