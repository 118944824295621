.about {
  padding: 40px 0;
}

.about img {
  width: 100%;
  display: block;
}

.about .profile-pic {
  border-radius: 10px;
  max-width: 350px;
  margin: 0 auto;
}

.about h3 {
  margin-top: 30px;
}

.about p {
  margin: 10px 0 0;
}

.about .company-pic {
  max-width: 250px;
  margin-top: 30px;
}

@media screen and (min-width: 768px)  {
  .about h3 {
    margin-top: 0;
  }

  .about .profile-pic {
    margin: 0;
  }

  .about .company-pic {
    margin-top: 0;
    max-width: 100%;
  }
}