body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-padding {
  padding: 0;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

p {
  font-size: 16px;
  margin: 0px;
}

a {
  color: #000;
}

.space-vertical-lg {
  padding-top: 50px;
  padding-bottom: 50px;
}

.space-vertical-md {
  padding-top: 30px;
  padding-bottom: 30px;
}

.space-top-lg {
  padding-top: 50px;
}

.space-top-md {
  padding-top: 30px;
}

.space-top-sm {
  padding-top: 10px;
}

.space-bottom-md {
  padding-bottom: 30px;
}

.space-bottom-sm {
  padding-bottom: 10px;
}


.border-bottom { 
  border-bottom : 1px solid;
}

.border-top {
  border-top: 1px solid;
}

.bg {
  background-color: #f9f9f9;
  border-color: #eee;
}

.center-y {
  display: flex;
  height: 100%;
  align-items: center;
}

.center-x-y {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.spacer {
  height: 30px;
}

@media screen and (min-width: 768px) {
  p {
    font-size: 18px;
  }
}
.mobile-nav {
  padding: 10px;
  border-bottom: 1px solid #000;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.mobile-nav.open {
  height: 100%;
}

.nav-content.open {
  height: 100%;
  margin-top: 30px;
}

.items-wrapper ul {
  padding: 0;
}

.items-wrapper li {
  list-style: none;
  border-top: 1px solid #bdbdbd;
}

.items-wrapper li:last-child {
  border-bottom: 1px solid #bdbdbd;
}

.items-wrapper li a {
  padding: 20px 20px;
  color: #000;
  text-decoration: none;
  display: block;
}

.items-wrapper li a.active {
  font-weight: 700;
}

.mobile-nav-height {
  height: 76px; 
  display: block;
}

.burger-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.burger {
  background-color: transparent;
  border: none;
  padding: 25px;
  position: relative;
}

.burger img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.mobile-nav .nav-icon {
  height: 55px;
}
.desktop-nav {
  border-bottom: 2px solid #383838;
  padding: 14px 0;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #f9f9f9;
}

.desktop-nav .nav-icon {
  height: 55px;
}

.desktop-nav .nav-items-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
}

.desktop-nav .nav-items-wrapper a {
  color: #000;
  text-decoration: none;
  margin: 0 20px;
  font-weight: 500;
  transition: border 0.4s;
  border-bottom: 1px solid #f9f9f9;
}

.desktop-nav .nav-items-wrapper a.active {
  font-weight: 700;
}

.desktop-nav .nav-items-wrapper a:hover {
  border-bottom: 1px solid #000;
}

.desktop-nav .nav-items-wrapper a:first-child {
  margin: 0 20px 0 0;
}

.desktop-nav-height {
  height: 85px; 
  display: block;
}
.section-header h3 {
  color: #383838;
}

.section-header.large h3 {
  font-size: 30px;
}
.footer {
  min-height: 400px;
  margin: 150px 0 0;
}

.footer .image-map {
  width: 100%;
  margin: 30px 0 50px;
  border-radius: 10px;
  border: 1px solid black;
  max-width: 450px;
}

.footer p {
  margin: 10px 0 0;
}

.footer p span{
  font-weight: 500;
  display: block;
}

.footer h3 {
  margin-bottom: 20px;
}

.footer .social-logo {
  height: 50px;
  width: 50px;
}

.footer .social-logo:hover {
  opacity: .8;
}

@media screen and (min-width: 768px) {

  .footer p {
    margin: 0 0 10px;
  }

  .footer .image-map {
    margin: 0 0 50px;
  } 
}
h2.page-header {
  font-size: 32px;
  font-weight: 700;
  color: #383838;
}

h4.page-subheader {
  font-size: 20px;
  margin: 10px 0 0;
}

.previous-job .image {
  height: 408px;
  width: 100%;
  background-size: cover;
  border-radius: 5px;
}

.previous-job p {
  margin-top: 10px;
}

.previous-job h3 {
  margin: 10px 0;
  font-size: 20px;
  border: none;
  padding: 30px 0 0;
  font-weight: 500;
}

.text-block .wrapper {
  margin-top: 10px;
}
.video-bg-wrapper video {
  width: 100%;
  min-height: 100%;
  display: block;
}

@media screen and (min-width: 2000px) {
  .video-bg-wrapper {
    -webkit-transform: translateY(-14%);
            transform: translateY(-14%);  
  }
}

.service-wrapper {
  padding: 20px 0;
}


.about {
  padding: 40px 0;
}

.about img {
  width: 100%;
  display: block;
}

.about .profile-pic {
  border-radius: 10px;
  max-width: 350px;
  margin: 0 auto;
}

.about h3 {
  margin-top: 30px;
}

.about p {
  margin: 10px 0 0;
}

.about .company-pic {
  max-width: 250px;
  margin-top: 30px;
}

@media screen and (min-width: 768px)  {
  .about h3 {
    margin-top: 0;
  }

  .about .profile-pic {
    margin: 0;
  }

  .about .company-pic {
    margin-top: 0;
    max-width: 100%;
  }
}
.about {
  padding: 40px 0;
}

.about img {
  width: 100%;
  display: block;
}

.about .profile-pic {
  border-radius: 10px;
  max-width: 350px;
  margin: 0 auto;
}

.about h3 {
  margin-top: 30px;
}

.about p {
  margin: 10px 0 0;
}

.about .company-pic {
  max-width: 250px;
  margin-top: 30px;
}

@media screen and (min-width: 768px)  {
  .about h3 {
    margin-top: 0;
  }

  .about .profile-pic {
    margin: 0;
  }

  .about .company-pic {
    margin-top: 0;
    max-width: 100%;
  }
}
.hero-block-wrapper {
  position: relative;
}

.hero-block-bg {
  padding: 0;
  overflow: hidden;
  max-height: 900px;
}

.hero-block-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}

.hero-block .hero-logo {
  width: 100%;
  max-width: 250px;
  display: inline-block;
}

.logo-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .hero-block .hero-logo {
    max-width: 450px;
  }
}
.title-with-text-block .wrapper {
  margin-top: 20px;
}

.title-with-text-block h4 {
  margin: 0;
  font-size: 20px;
}

.title-with-text-block p {
  margin: 0;
}
