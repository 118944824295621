.mobile-nav {
  padding: 10px;
  border-bottom: 1px solid #000;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.mobile-nav.open {
  height: 100%;
}

.nav-content.open {
  height: 100%;
  margin-top: 30px;
}

.items-wrapper ul {
  padding: 0;
}

.items-wrapper li {
  list-style: none;
  border-top: 1px solid #bdbdbd;
}

.items-wrapper li:last-child {
  border-bottom: 1px solid #bdbdbd;
}

.items-wrapper li a {
  padding: 20px 20px;
  color: #000;
  text-decoration: none;
  display: block;
}

.items-wrapper li a.active {
  font-weight: 700;
}

.mobile-nav-height {
  height: 76px; 
  display: block;
}

.burger-wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.burger {
  background-color: transparent;
  border: none;
  padding: 25px;
  position: relative;
}

.burger img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.mobile-nav .nav-icon {
  height: 55px;
}